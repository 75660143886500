// imported in each class
import { FieldType } from './field';
import { EsAuthor } from './EsAuthor';

export enum SearchOperator {
  gt = 'gt',
  lt = 'lt',
  gte = 'gte',
  lte = 'lte',
  c = 'c',
  e = 'e',
  ne = 'ne',
  n = 'n',
  nn = 'nn'
}

export enum OperatorType {
  MATCH = 'MATCH',
  TERM = 'TERM',
  RANGE = 'RANGE',
  EXISTS = 'EXISTS'
}

export interface SearchItemLabelByType {
  searchItem: SearchItem;
  userList: EsAuthor[];
  currentLanguage: string;
}

export class SearchItem {

  constructor(
    public id: number,
    public keyword: string,
    public path: string,
    public value: string,
    public canDelete: boolean = true,
    public type: FieldType = null,
    public operator?: SearchOperator,
    public label?: string
  ) {
  }

  public static readonly OPERATOR_SEPARATOR = ':';
  public count?: number;
  public fieldType?: FieldType;
  public authorizeSearchByDirectInput?: boolean;
  public callAutocompletionOnDirectInput?: boolean;

  public static getOperatorType(operator: SearchOperator): OperatorType {
    let type: OperatorType;
    switch (operator) {
      case SearchOperator.gt:
      case SearchOperator.gte:
      case SearchOperator.lt:
      case SearchOperator.lte:
        type = OperatorType.RANGE;
        break;
      case SearchOperator.c:
        type = OperatorType.MATCH;
        break;
      case SearchOperator.e:
      case SearchOperator.ne:
        type = OperatorType.TERM;
        break;
      case SearchOperator.n:
      case SearchOperator.nn:
        type = OperatorType.EXISTS;
        break;
    }
    return type;
  }

  public static setSearchBehavior(searchItem: SearchItem): SearchItem {
    switch (searchItem.fieldType) {
      case FieldType.AUTHOR:
        searchItem.authorizeSearchByDirectInput = false;
        searchItem.callAutocompletionOnDirectInput = true;
        break;
      case FieldType.DATE:
        searchItem.authorizeSearchByDirectInput = false;
        searchItem.callAutocompletionOnDirectInput = false;
        break;
      case FieldType.DECIMAL:
      case FieldType.INTEGER:
        searchItem.authorizeSearchByDirectInput = true;
        searchItem.callAutocompletionOnDirectInput = false;
        break;
      default:
        searchItem.authorizeSearchByDirectInput = true;
        searchItem.callAutocompletionOnDirectInput = true;
        break;
    }
    return searchItem;
  }

  public static getSearchItemLabel(searchItemLabelByType: SearchItemLabelByType): string {
    if (!searchItemLabelByType.searchItem) {
      return '';
    }
    if (!searchItemLabelByType.searchItem.fieldType) {
      return searchItemLabelByType.searchItem.value;
    }
    switch (searchItemLabelByType.searchItem.fieldType) {
      case FieldType.AUTHOR:
        return SearchItem.getUserFirstNameLastName(searchItemLabelByType.searchItem, searchItemLabelByType.userList);
      case FieldType.DATE:
        return SearchItem.getLocaleDate(searchItemLabelByType.searchItem, searchItemLabelByType.currentLanguage);
      default:
        return searchItemLabelByType.searchItem.value;
    }
  }

  private static getUserFirstNameLastName(searchItem: SearchItem, userList: EsAuthor[]): string {
    const searchedUser: EsAuthor = userList.find((user: EsAuthor) => user.id === +searchItem.value);
    return searchedUser ? EsAuthor.getUserLabel(searchedUser) : searchItem.value;
  }

  private static getLocaleDate(searchItem: SearchItem, currentLanguage: string): string {
    return new Date(searchItem.value).toLocaleDateString(currentLanguage);
  }

  public static isStartWithFragment(searchItem: SearchItem): boolean {
    switch (searchItem.fieldType) {
      case FieldType.INTEGER:
      case FieldType.DECIMAL:
        return false;
      default:
        return true;
    }
  }
}
