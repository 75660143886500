import * as _ from 'lodash';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { NgRedux } from '@angular-redux/store';
import { Observable, of, OperatorFunction, pipe, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ToastaService } from 'ngx-toasta';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { ConfigurationActions } from '../../../../modules/configuration/configuration.actions';
import { IAppState } from '../../../../store/model';
import { SocketService } from '../../../services/socket/socket.service';
import { AuthenticationService } from '../../../../modules/authentication/authentication.service';
import { CriticalDataService } from '../../critical-data/service/critical-data.service';
import { CurrentCommentService } from '../../current-comment/service/current-comment.service';
import { CdxDocument } from '../../../models/cdx-document';
import { Url } from '../../../models/url';
import { CdxTask } from '../../../models/cdx-task';
import { Entity } from '../../../models/Entity';
import { CdxComment, CdxReply } from '../../../models/cdx-comment';
import { HttpErrorInterceptor } from '../../../../modules/http-error-interceptor/http-error-interceptor';
import { UserSocketHttpResponse } from '../../../models/user-socket-http-response';
import { EsPage } from '../../../models/es-page';
import { ActivityDiff, CdxActivity } from '../../../models/cdx-activity';
import { CdxAttachment } from '../../../models/cdx-attachment';
import { CdxFile } from '../../../models/cdx-file';
import { Utils } from '../../../utils/utils';
import { UserCredentials } from '../../../models/UserCredentials';
import { EsAuthor } from '../../../models/EsAuthor';
import { CriticalDataAction } from '../../critical-data/action/critical-data.action';
import { MetadataCodes } from '../../../models/metadata';
import { Lock, LockInformation } from '../../../models/lock';
import { AccessFunction } from '../../../models/access';

export enum DELETE_MODE {
  SOFT = 'soft',
  HARD = 'hard'
}

export namespace DELETE_MODE {
 export function getFunction(deleteMode: any) {
    if (deleteMode === DELETE_MODE.SOFT) {
      return AccessFunction.DELETE;
    } else if (deleteMode === DELETE_MODE.HARD) {
      return AccessFunction.HARDDELETE;
    } else {
      return;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export abstract class AbstractDetailsService {

  public static readonly PAGE_SIZE = 24;
  public static readonly MAX_SIZE = 200;
  public static readonly LOCK_ARCHIVE = 'lock-archive';
  public static readonly UNLOCK_ARCHIVE = 'unlock-archive';
  public static readonly UPDATE_LOCK_ARCHIVE_INFO = 'update-lock-archive-info';

  protected static query(): Observable<HttpParams> {
    const httpParams = new HttpParams();
    return of(httpParams);
  }

  constructor(
    protected httpClient: HttpClient,
    protected configAction: ConfigurationActions,
    protected ngRedux: NgRedux<IAppState>,
    protected toastaService: ToastaService,
    protected translateService: TranslateService,
    protected socketService: SocketService,
    protected authenticationService: AuthenticationService,
    protected location: Location,
    protected router: Router,
    protected criticalDataService: CriticalDataService,
    protected currentCommentService: CurrentCommentService,
  ) {
  }

  /*START getting urls*/
  protected abstract getNexiaObjectDetailsUrl(nexiaObjectId: string): string;

  protected abstract getInternalRouteDetailsUrl(nexiaObjectId: string): string[];

  protected abstract getPostPutCommentUrl(comment: CdxComment): string;
  protected abstract getLoadDeleteCommentUrl(nexiaObjectId: string, comId: string): string;

  protected abstract getPostPutReplyUrl(nexiaObjectidId: string, commentId: string): string;
  protected abstract getDeleteReplyUrl(nexiaObjectId: string, commentId: string, replyId: string): string;

  protected abstract getHistoryUrl(nexiaObjectId: string): string;

  protected abstract getLoadDeleteDownloadAttachmentUrl(nexiaObjectId: string, attachmentId: string): string;
  protected abstract getUpdateUploadAttachmentUrl(nexiaObjectId: string): string;

  protected abstract getRestoreVersionUrl(nexiaObjectId: string, numVersion: string): string;

  protected abstract getCurrentActivityDiffUrl(nexiaObjectId: string, evtId: number, entityType: string): string;

  protected abstract getUpdateTeamsUrl(nexiaObjectId: string): string;

  protected abstract getDeleteNexiaObjectUrl(nexiaObjectId: string): string;

  protected abstract getHardDeleteNexiaObjectsUrl(): string;

  protected abstract getUpdateArchiveUrl(): string;
  /*END getting urls*/

  /*START calls to details actions*/
  protected abstract detailsActionLoadCommentStart(): void;
  protected abstract detailsActionLoadCommentSucceeded(comment: CdxComment): void;
  protected abstract detailsActionLoadCommentFailed(error: any): void;

  protected abstract detailsActionLoadHistoryStart(): void;
  protected abstract detailsActionLoadHistorySucceeded(activities: EsPage<CdxActivity>): void;
  protected abstract detailsActionLoadHistoryFailed(error: any): void;

  protected abstract detailsActionLoadAttachmentStart(): void;
  protected abstract detailsActionLoadAttachmentSucceeded(attachment: CdxAttachment, tempAttachmentId: string): void;
  protected abstract detailsActionLoadAttachmentFailed(error: any): void;

  protected abstract detailsActionUploadAttachmentSucceeded(tempAttachment: CdxAttachment): void;

  protected abstract detailsActionLoadCurrentActivityDiffStart(): void;
  protected abstract detailsActionLoadCurrentActivityDiffSucceeded(currentActivityDiff: ActivityDiff): void;
  protected abstract detailsActionLoadCurrentActivityDiffFailed(error: any): void;

  protected abstract detailsActionLoadMetaStart(): void;
  protected abstract detailsActionLoadMetaSucceeded(meta: {-readonly [key in MetadataCodes]?: any}): void;
  protected abstract detailsActionLoadMetaFailed(error: any): void;
  /*END calls to details actions*/

  protected abstract updateToken(headers: HttpHeaders, id: string): void;

  /*START methods called from abstract && extenders*/
  protected _addVisibleCriticalField(criticalFieldCode: string): void {
    this.criticalDataService.addCriticalFieldToStore(criticalFieldCode);
  }

  protected _errorManagement(error: Error): void {
    this.toastaService.error(this.translateService.instant(error.message));
  }

  protected _loadNexiaObjectDetailsVersion(id: string, versionNumber: number): Observable<CdxDocument | Entity | CdxTask> {
    try {
      return AbstractDetailsService.query().pipe(
        this.setVersionNumber(versionNumber),
        this.requestNexiaObjectDetails(id)
      );
    } catch (error) {
      return of(error);
    }
  }

  protected _loadReadCriticalFieldValue(id: string, fieldcode: string, versionNumber: number = null): Observable<CdxDocument | Entity | CdxTask> {
    return AbstractDetailsService.query().pipe(
      this.setReadCritical(fieldcode),
      this.setVersionNumber(versionNumber),
      this.requestNexiaObjectDetails(id)
    );
  }

  protected _addComment(comment: CdxComment, token: string): Observable<UserSocketHttpResponse> {
    let headers = Utils.objectTokenHeader(token);
    headers = headers.set(HttpErrorInterceptor.BYPASS_HEADER, '');
    return this.socketService.post(
      Url.getProtectedApiBaseUrl(this.configAction) + this.getPostPutCommentUrl(comment), comment,
      {
        responseType: 'text',
        headers: headers,
        observe: 'response'
      });
  }

  protected _updateComment(comment: CdxComment, token: string): Observable<UserSocketHttpResponse> {
    let headers = Utils.objectTokenHeader(token);
    headers = headers.set(HttpErrorInterceptor.BYPASS_HEADER, '');
    return this.socketService.put(
      Url.getProtectedApiBaseUrl(this.configAction) + this.getPostPutCommentUrl(comment), comment,
      {
        responseType: 'text',
        headers: headers,
        observe: 'response'
      });
  }

  protected _loadComment(nexiaOnjectId: string, comId: string): void {
    try {
      this.detailsActionLoadCommentStart();
      this.httpClient.get<CdxComment>(Url.getProtectedApiBaseUrl(this.configAction) + this.getLoadDeleteCommentUrl(nexiaOnjectId, comId))
        .subscribe(comment =>
            this.detailsActionLoadCommentSucceeded(comment),
          (error: HttpErrorResponse) => this.detailsActionLoadCommentFailed(error));
    } catch (error) {
      this.detailsActionLoadCommentFailed(error);
    }
  }

  protected _deleteComment(comment: CdxComment, token: string): Observable<UserSocketHttpResponse> {
    let headers = Utils.objectTokenHeader(token);
    headers = headers.set(HttpErrorInterceptor.BYPASS_HEADER, '');
    return this.socketService.delete(
      Url.getProtectedApiBaseUrl(this.configAction) + this.getLoadDeleteCommentUrl(comment.cdx_doc_id, comment.cdx_id),
      {
        observe: 'response',
        headers: headers
      });
  }

  protected _addReply(nexiaOnjectId: string, commentId: string, token: string, reply: CdxReply): Observable<UserSocketHttpResponse> {
    let headers = Utils.objectTokenHeader(token);
    headers = headers.set(HttpErrorInterceptor.BYPASS_HEADER, '');
    return this.socketService.post(
      Url.getProtectedApiBaseUrl(this.configAction) + this.getPostPutReplyUrl(nexiaOnjectId, commentId), reply,
      {
        responseType: 'text',
        headers: headers,
        observe: 'response'
      });
  }

  protected _updateReply(nexiaOnjectId: string, commentId: string, token: string, reply: CdxReply): Observable<UserSocketHttpResponse> {
    let headers = Utils.objectTokenHeader(token);
    headers = headers.set(HttpErrorInterceptor.BYPASS_HEADER, '');
    return this.socketService.put(
      Url.getProtectedApiBaseUrl(this.configAction) + this.getPostPutReplyUrl(nexiaOnjectId, commentId), reply,
      {
        responseType: 'text',
        headers: headers,
        observe: 'response'
      });
  }

  protected _deleteReply(nexiaOnjectId: string, commentId: string, token: string, replyId: string): Observable<UserSocketHttpResponse> {
    let headers = Utils.objectTokenHeader(token);
    headers = headers.set(HttpErrorInterceptor.BYPASS_HEADER, '');
    return this.socketService.delete(
      Url.getProtectedApiBaseUrl(this.configAction) + this.getDeleteReplyUrl(nexiaOnjectId, commentId, replyId),
      {
        observe: 'response',
        headers: headers
      });
  }

  protected _loadNexiaObjectHistory(nexiaObjectId: string): void {
    try {
      this.detailsActionLoadHistoryStart();
      this.httpClient.get<EsPage<CdxActivity>>(Url.getProtectedApiBaseUrl(this.configAction) + this.getHistoryUrl(nexiaObjectId))
        .subscribe((activities: EsPage<CdxActivity>) =>
            this.detailsActionLoadHistorySucceeded(activities),
          (error: HttpErrorResponse) => this.detailsActionLoadHistoryFailed(error));
    } catch (error) {
      this.detailsActionLoadHistoryFailed(error);
    }
  }

  protected _loadAttachment(nexiaObjectId: string, attId: string, tempAttachmentId: string = null): void {
    try {
      this.detailsActionLoadAttachmentStart();
      this.httpClient.get<CdxAttachment>(Url.getProtectedApiBaseUrl(this.configAction) + this.getLoadDeleteDownloadAttachmentUrl(nexiaObjectId, attId))
        .subscribe(attachment => this.detailsActionLoadAttachmentSucceeded(attachment, tempAttachmentId),
          (error: HttpErrorResponse) => this.detailsActionLoadAttachmentFailed(error));
    } catch (error) {
      this.detailsActionLoadAttachmentFailed(error);
    }
  }

  protected _deleteAttachment(attachment: CdxAttachment, token: string): Observable<UserSocketHttpResponse> {
    let headers = Utils.objectTokenHeader(token);
    headers = headers.set(HttpErrorInterceptor.BYPASS_HEADER, '');
    return this.socketService.delete(
      Url.getProtectedApiBaseUrl(this.configAction) + this.getLoadDeleteDownloadAttachmentUrl(attachment.cdx_doc_id, attachment.cdx_id),
      {
        observe: 'response',
        headers: headers
      });
  }

  protected _downloadAttachment(nexiaObjectId: string, attId: string, token: string, isThumbnail: boolean): Observable<CdxFile> {
    try {
      const url = isThumbnail ? Url.THUMB : Url.FILE;
      return this.httpClient.get(
        Url.getProtectedApiBaseUrl(this.configAction) + this.getLoadDeleteDownloadAttachmentUrl(nexiaObjectId, attId) + '/' + url,
        {
          observe: 'response', responseType: 'arraybuffer',
          headers: Utils.objectTokenHeader(token)
        })
        .pipe(map((response: HttpResponse<any>) => {
          this.updateToken(response.headers, nexiaObjectId);
          // this.documentDetailsAction.updateToken(response.headers);
          return Utils.httpResponseToCdxFile(response);
        }, (error: HttpErrorResponse) => {
          console.error('error 1', error);
          return of(error);
        }));
    } catch (err) {
      console.error('error 2', err);
      return of(err);
    }
  }

  protected _uploadAttachment(nexiaObjectId: string, file: File, token: string): void {
    if (file != null) {
      const tempAttachment = this.createTempAttachment(nexiaObjectId, file);
      this.detailsActionUploadAttachmentSucceeded(tempAttachment);
      const formData = new FormData();
      formData.append('file', file, file.name);
      const attachment = new CdxAttachment();
      attachment.cdx_doc_id = nexiaObjectId;
      formData.append('attachment', JSON.stringify(attachment));
      let headers = Utils.objectTokenHeader(token);
      headers = headers.set(HttpErrorInterceptor.BYPASS_HEADER, '');
      this.socketService.post(
        Url.getProtectedApiBaseUrl(this.configAction) + this.getUpdateUploadAttachmentUrl(nexiaObjectId),
        formData,
        {
          responseType: 'text',
          headers: headers,
          observe: 'response'
        })
        .subscribe((userSocketHttpResponse: UserSocketHttpResponse) => {
            this._loadAttachment(nexiaObjectId, userSocketHttpResponse.id, tempAttachment.cdx_id);
            this._loadNexiaObjectHistory(nexiaObjectId);
            this.updateToken(userSocketHttpResponse.httpResponse.headers, nexiaObjectId);
          },
          (error) => {
            this._errorManagement(error);
          });
    }
  }

  protected _updateAttachment(attachment: CdxAttachment, token: string): void {
    let headers = Utils.objectTokenHeader(token);
    headers = headers.set(HttpErrorInterceptor.BYPASS_HEADER, '');
    this.socketService.put(
      Url.getProtectedApiBaseUrl(this.configAction) + this.getUpdateUploadAttachmentUrl(attachment.cdx_doc_id), attachment,
      {
        responseType: 'text',
        headers: headers,
        observe: 'response'
      })
      .subscribe((userSocketHttpResponse: UserSocketHttpResponse) => {
          this.updateToken(userSocketHttpResponse.httpResponse.headers, attachment.cdx_doc_id);
          this._loadNexiaObjectHistory(attachment.cdx_doc_id);
        },
        (error) => {
          this._errorManagement(error);
          this._loadAttachment(attachment.cdx_doc_id, attachment.cdx_id);
        });
  }

  protected _updateMetaArchive(meta: {-readonly [key in MetadataCodes]?: any}, action: string, objectId: string, token: string, updateLock: boolean): Observable<boolean> {
    const updateMetaSucceeded$: Subject<boolean> = new Subject<boolean>();
    let headers = token ? Utils.objectTokenHeader(token) : new HttpHeaders();
    headers = headers.set(HttpErrorInterceptor.BYPASS_HEADER, '');
    if (updateLock) {
      this.detailsActionLoadMetaStart();
    }
    try {
      let request: Observable<any>;
      if (action === AbstractDetailsService.LOCK_ARCHIVE) {
        request = this._lockArchive(headers, objectId);
      }
      if (action === AbstractDetailsService.UNLOCK_ARCHIVE) {
        request = this._unlockArchive(headers, objectId);
      }
      if (action === AbstractDetailsService.UPDATE_LOCK_ARCHIVE_INFO) {
        const lockInfo: LockInformation = (meta[MetadataCodes.ARCHIVE_LOCK] as Lock).lock_information;
        request = this._updateLockInfo(lockInfo, headers, objectId);
      }
      request.pipe(
        switchMap((response: HttpResponse<any>) => {
          return updateLock ? this.getArchiveLock(objectId) : of(new HttpResponse(meta[MetadataCodes.ARCHIVE_LOCK]));
        })
      ).subscribe({
          next: (response: HttpResponse<Lock>) => {
            if (updateLock) {
              (meta[MetadataCodes.ARCHIVE_LOCK] as Lock) = response.body;
              this.detailsActionLoadMetaSucceeded(meta);
            }
            updateMetaSucceeded$.next(true);
            updateMetaSucceeded$.complete();
          },
          error: (error: HttpErrorResponse) => {
            updateMetaSucceeded$.next(false);
            updateMetaSucceeded$.complete();
            if (updateLock) {
              this.detailsActionLoadMetaFailed(error);
            }
          }
        }
      );
    } catch (e) {
      updateMetaSucceeded$.next(false);
      updateMetaSucceeded$.complete();
      if (updateLock) {
        this.detailsActionLoadMetaFailed(e);
      }
    }
    return updateMetaSucceeded$.asObservable();
  }

  private getArchiveLock(objectId: string): Observable<HttpResponse<Lock>> {
    return this.httpClient.get(Url.getProtectedApiBaseUrl(this.configAction) + Url.DOCUMENTS + objectId + '/' + Url.LOCK + Url.FILE,
      {
        observe: 'response'
      });
  }

  protected updateArchive(docId: string, file: File): Observable<boolean> {
    const updateDone$: Subject<boolean> = new Subject<boolean>();
    const headers = new HttpHeaders().set(HttpErrorInterceptor.BYPASS_HEADER, '');
    const formData = new FormData();
    formData.append('file', file, file.name);
    const doc: CdxDocument = new CdxDocument();
    doc.cdx_id = docId;
    delete doc.cdx_datas;
    formData.append('document', JSON.stringify(doc));
    formData.append('mode', 'PATCH');
    try {
    this.socketService.post(
      Url.getProtectedApiBaseUrl(this.configAction) + this.getUpdateArchiveUrl(),
      formData,
      {
        responseType: 'text',
        headers: headers,
        observe: 'response'
      })
      .subscribe((userSocketHttpResponse: UserSocketHttpResponse) => {
          console.log('>>>>>>>>>>>>>>>>>>socket response from update archive<<<<<<<<<<<<<<<<<<');
          updateDone$.next(true);
          updateDone$.complete();
        },
        (error) => {
          this._errorManagement(error);
          updateDone$.next(false);
          updateDone$.complete();
        });
    } catch (error) {
      this._errorManagement(error);
      updateDone$.next(false);
      updateDone$.complete();
    }
    return updateDone$.asObservable();
  }

  private _lockArchive(headers: HttpHeaders, objectId: string): Observable<any> {
    return this.httpClient.post(Url.getProtectedApiBaseUrl(this.configAction) + Url.DOCUMENTS + objectId + '/' + Url.LOCK + Url.FILE, null,
      {
        responseType: 'text',
        headers: headers,
        observe: 'response'
      });
  }

  private _unlockArchive(headers: HttpHeaders, objectId: string): Observable<any> {
    return this.httpClient.delete(Url.getProtectedApiBaseUrl(this.configAction) + Url.DOCUMENTS + objectId + '/' + Url.LOCK + Url.FILE, {
      headers: headers,
      observe: 'response'
    });
  }

  private _updateLockInfo(lockInformation: LockInformation, headers: HttpHeaders, objectId: string): Observable<any> {
    return this.httpClient.put(Url.getProtectedApiBaseUrl(this.configAction) + Url.DOCUMENTS + objectId + '/' + Url.LOCK + Url.FILE + Url.DETAILS,
      lockInformation,
      {
        responseType: 'text',
        headers: headers,
        observe: 'response'
      });
  }

  protected _loadCurrentActivityDiff(nexiaObjectId: string, evtId: number, isJustOpen: boolean, entityType?: string): void {
    console.log('loadCurrentActivityDiff of : document id : ' + nexiaObjectId + 'event id :' + evtId + ' isJustOpen :' + isJustOpen);
    this.detailsActionLoadCurrentActivityDiffStart();
    try {
      if (isJustOpen) {
        this.httpClient.get<ActivityDiff>(Url.getProtectedApiBaseUrl(this.configAction) + this.getCurrentActivityDiffUrl(nexiaObjectId, evtId, entityType)).subscribe(
          (currentActivityDiff: ActivityDiff) => {
            this.detailsActionLoadCurrentActivityDiffSucceeded(currentActivityDiff);
          },
          (error: HttpErrorResponse) => this.detailsActionLoadCurrentActivityDiffFailed(error));
      } else {
        this.detailsActionLoadCurrentActivityDiffSucceeded(new ActivityDiff());
      }
    } catch (error) {
      this.detailsActionLoadCurrentActivityDiffFailed(error);
    }
  }

  protected _updateTeams(nexiaObjectId: string, token: string, teamIds: string[]): Observable<UserSocketHttpResponse> {
    if ( !teamIds ) {
      teamIds = [];
    }

    let headers = Utils.objectTokenHeader(token);
    headers = headers.set(HttpErrorInterceptor.BYPASS_HEADER, '');
    return this.socketService.put(
      Url.getProtectedApiBaseUrl(this.configAction) + this.getUpdateTeamsUrl(nexiaObjectId), teamIds,
      {
        responseType: 'text',
        headers: headers,
        observe: 'response'
      });
  }

  protected _deleteNexiaObject(nexiaObjectId: string, mode: DELETE_MODE): Observable<UserSocketHttpResponse> {
    const headers = new HttpHeaders().set(HttpErrorInterceptor.BYPASS_HEADER, '');
    const params: HttpParams = new HttpParams().set('mode', mode);
    return this.socketService.delete(
      Url.getProtectedApiBaseUrl(this.configAction) + this.getDeleteNexiaObjectUrl(nexiaObjectId),
      {
        observe: 'response',
        params: params,
        headers: headers
      });
  }

  // TODO To be reviewed
  protected _hardDeleteNexiaObject(nexiaObjectIds: string[]): Observable<UserSocketHttpResponse> {
    const headers = new HttpHeaders().set(HttpErrorInterceptor.BYPASS_HEADER, '');

    const formData = new FormData();
    formData.append('mode', 'hard');
    formData.append('ids', '' + nexiaObjectIds);
    return this.socketService.post(
      Url.getProtectedApiBaseUrl(this.configAction) + this.getHardDeleteNexiaObjectsUrl(), formData,
      {
        observe: 'response',
        headers: headers
      });
  }
  /*END methods called from abstract && extenders*/

  /*START methods called from abstract*/
  protected setVersionNumber(versionNumber: number): OperatorFunction<HttpParams, HttpParams> {
    return pipe(
      map((params: HttpParams) => {
        if (!!versionNumber) {
          params = params.append('revision', '' + versionNumber);
        }
        return params;
      })
    );
  }

  protected requestNexiaObjectDetails(nexiaObjectId: string): OperatorFunction<HttpParams, (CdxDocument | Entity | CdxTask)> {
    return switchMap(params => {
      return this.httpClient.get<CdxDocument | Entity | CdxTask>(Url.getProtectedApiBaseUrl(this.configAction) + this.getNexiaObjectDetailsUrl(nexiaObjectId), { params });
    });
  }

  protected setReadCritical(readCritical: string): OperatorFunction<HttpParams, HttpParams> {
    return map((params) => {
      return params
        .set('readCritical', readCritical !== CriticalDataAction._ALL ? 'cdx_datas.' + readCritical : readCritical);
    });
  }

  private createTempAttachment(entityId: string, file: File): CdxAttachment {
    const userCredentials: UserCredentials = this.authenticationService.getUserCredentials();
    const attachment: CdxAttachment = new CdxAttachment();
    attachment.cdx_creation_date = new Date().getTime();
    attachment.cdx_doc_id = entityId;
    attachment.cdx_id = '' + Math.random().toString(36).substr(2, 16);
    attachment.cdx_author = new EsAuthor();
    attachment.cdx_author.firstname = userCredentials.firstname;
    attachment.cdx_author.lastname = userCredentials.lastname;
    attachment.cdx_title = file.name;
    return attachment;
  }

  private formatReviewError(error: any): any {
    const reviewError: any = _.cloneDeep(error);
    let errormessage = '';
    if ( !!reviewError.message) {
      errormessage = reviewError.message;
    }

    if (!!reviewError.error) {
      try {
        const reviewError_error = JSON.parse(reviewError.error);
        if (!!reviewError_error.fields && reviewError_error.fields.length > 0) {
          const errorMessage = reviewError_error.links[0];
          errormessage += ' -> (fields) : ' + errorMessage;
        }
        if (!!reviewError_error.links && reviewError_error.links.length > 0) {
          let  errorMessage = reviewError_error.links[0];
          let errorMessageToTranslate = null;
          switch (errorMessage) {
            case 'version conflict' : {
              errorMessageToTranslate = 'ERRORS.versionConflict';
              break;
            }
          }
          if (!!errorMessageToTranslate) {
            errorMessage = this.translateService.instant(errorMessageToTranslate);
          }
          errormessage += ' -> (links) : ' + errorMessage;
        }
        if (!!reviewError_error.documents && reviewError_error.documents.length > 0) {
          let errorMessage = reviewError_error.documents[0];
          let errorMessageToTranslate = null;
          switch (errorMessage) {
            case 'user don\'t have habilitations to reindex this document' : {
              errorMessageToTranslate = 'ERRORS.userDontHaveHabilitationsToReindexThisDocument';
              break;
            }
            case 'a draft in progress exist for this document' : {
              errorMessageToTranslate = 'ERRORS.aDraftInProgressExistForThisDocument';
              break;
            }
            case 'document type cannot be changed' : {
              errorMessageToTranslate = 'ERRORS.documentTypeCannotBeChanged';
              break;
            }
            case 'version conflict' : {
              errorMessageToTranslate = 'ERRORS.versionConflict';
              break;
            }
          }
          if (!!errorMessageToTranslate) {
            errorMessage = this.translateService.instant(errorMessageToTranslate);
          }

          errormessage += ' -> (documents) : ' + errorMessage;
        }
      } catch (e) {
        // reviewError.error is not a JSON object don't matter
      }
    }
    reviewError.message = errormessage;
    return reviewError;
  }
  /*END methods called from abstract*/

  // l'appel à l'affichage de tous les champs critique a déjà été fait, ils sont tous dans le store
  public showAllCriticalFieldsValue(): void {
    this.criticalDataService.addAllToVisibleCriticalFieldsStore();
  }

  public addViewCriticalFieldsToStore(viewCriticalFields: string[]): void {
    this.criticalDataService.addViewCriticalFieldsToStore(viewCriticalFields);
  }

  public addVisibleCriticalPdfToStore(isVisible: boolean): void {
    this.criticalDataService.addVisibleCriticalPdfToStore(isVisible);
  }

  public hideAllCriticalFieldsValue(): void {
    this.criticalDataService.removeAllFromVisibleCriticalFieldsStore();
  }

  public removeViewCriticalFieldsStore(): void {
    this.criticalDataService.removeViewCriticalFieldsStore();
  }

  public restoreVersion(nexiaObjectId: string, numVersion: string): Observable<boolean> {
    const url: string = Url.getProtectedApiBaseUrl(this.configAction) + this.getRestoreVersionUrl(nexiaObjectId, numVersion);
    const booleanSubject$: Subject<boolean> = new Subject<boolean>();
    const headers = new HttpHeaders();

    this.socketService.put(
      url, null,
      {
        responseType: 'text',
        headers: headers,
        observe: 'response'
      })
      .subscribe((userSocketHttpResponse: UserSocketHttpResponse) => {
          // this.updateToken(userSocketHttpResponse.httpResponse.headers, docId);
          booleanSubject$.next(true);
          booleanSubject$.complete();
          this.router.navigate(
            this.getInternalRouteDetailsUrl(nexiaObjectId),
            { replaceUrl: true , queryParams: { restoreTime: new Date().getTime() }});
        },
        (error) => {
          const reviewError: Error = this.formatReviewError(error);
          this._errorManagement(reviewError);
          booleanSubject$.next(true); // close modal dialog
          booleanSubject$.complete();
        });
    return booleanSubject$.asObservable();
  }
}
