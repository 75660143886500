import { Reducer } from 'redux';

import { Utils } from '../../../../utils/utils';
import { DocumentDetails } from '../../../../models/document-details';
import { PayloadAction } from '../../../../../store/payload-action';
import { CdxComment } from '../../../../models/cdx-comment';
import { CdxAttachment } from '../../../../models/cdx-attachment';
import { ActivityDiff } from '../../../../models/cdx-activity';

export class DocumentDetailsState {
  datas: DocumentDetails;
  displayInfoBlock: boolean;
  loading: boolean;
  error: any;
  loadPdf?: boolean;
}

const CURRENT_DOCUMENT_INITIAL_STATE: DocumentDetailsState = {
  datas: null,
  displayInfoBlock: false,
  loading: false,
  error: null,
  loadPdf: true
};

export class DocumentDetailsConstant {

  static readonly LOAD_DETAILS_START = '[DocumentDetails].LOAD_DETAILS_START';
  static readonly LOAD_DETAILS_SUCCEEDED = '[DocumentDetails].LOAD_DETAILS_SUCCEEDED';
  static readonly LOAD_DETAILS_FAILED = '[DocumentDetails].LOAD_DETAILS_FAILED';

  static readonly LOAD_DOC_DETAILS_START = '[DocumentDetails].LOAD_DOC_DETAILS_START';
  static readonly LOAD_DOC_DETAILS_SUCCEEDED = '[DocumentDetails].LOAD_DOC_DETAILS_SUCCEEDED';
  static readonly LOAD_DOC_DETAILS_FAILED = '[DocumentDetails].LOAD_DOC_DETAILS_FAILED';

  static readonly LOAD_ATTACHMENT_START = '[DocumentDetails].LOAD_ATTACHMENT_START';
  static readonly LOAD_ATTACHMENT_SUCCEEDED = '[DocumentDetails].LOAD_ATTACHMENT_SUCCEEDED';
  static readonly LOAD_ATTACHMENT_FAILED = '[DocumentDetails].LOAD_ATTACHMENT_FAILED';

  static readonly LOAD_HISTORY_START = '[DocumentDetails].LOAD_HISTORY_START';
  static readonly LOAD_HISTORY_SUCCEEDED = '[DocumentDetails].LOAD_HISTORY_SUCCEEDED';
  static readonly LOAD_HISTORY_FAILED = '[DocumentDetails].LOAD_HISTORY_FAILED';

  static readonly UPLOAD_ATTACHMENT_START = '[DocumentDetails].UPLOAD_ATTACHMENT_START';
  static readonly UPLOAD_ATTACHMENT_SUCCEEDED = '[DocumentDetails].UPLOAD_ATTACHMENT_SUCCEEDED';
  static readonly UPLOAD_ATTACHMENT_FAILED = '[DocumentDetails].UPLOAD_ATTACHMENT_FAILED';

  static readonly DELETE_ATTACHMENT_START = '[DocumentDetails].DELETE_ATTACHMENT_START';
  static readonly DELETE_ATTACHMENT_SUCCEEDED = '[DocumentDetails].DELETE_ATTACHMENT_SUCCEEDED';
  static readonly DELETE_ATTACHMENT_FAILED = '[DocumentDetails].DELETE_ATTACHMENT_FAILED';

  static readonly UPDATE_ATTACHMENT_START = '[DocumentDetails].UPDATE_ATTACHMENT_START';
  static readonly UPDATE_ATTACHMENT_SUCCEEDED = '[DocumentDetails].UPDATE_ATTACHMENT_SUCCEEDED';
  static readonly UPDATE_ATTACHMENT_FAILED = '[DocumentDetails].UPDATE_ATTACHMENT_FAILED';

  static readonly LOAD_COMMENT_START = '[DocumentDetails].LOAD_COMMENT_START';
  static readonly LOAD_COMMENT_SUCCEEDED = '[DocumentDetails].LOAD_COMMENT_SUCCEEDED';
  static readonly LOAD_COMMENT_FAILED = '[DocumentDetails].LOAD_COMMENT_FAILED';

  static readonly ADD_COMMENT_START = '[DocumentDetails].ADD_COMMENT_START';
  static readonly ADD_COMMENT_SUCCEEDED = '[DocumentDetails].ADD_COMMENT_SUCCEEDED';
  static readonly ADD_COMMENT_FAILED = '[DocumentDetails].ADD_COMMENT_FAILED';

  static readonly UPDATE_COMMENT_START = '[DocumentDetails].UPDATE_COMMENT_START';
  static readonly UPDATE_COMMENT_SUCCEEDED = '[DocumentDetails].UPDATE_COMMENT_SUCCEEDED';
  static readonly UPDATE_COMMENT_FAILED = '[DocumentDetails].UPDATE_COMMENT_FAILED';

  static readonly DELETE_COMMENT_START = '[DocumentDetails].DELETE_COMMENT_START';
  // static readonly DELETE_COMMENT_SUCCEEDED = '[DocumentDetails].DELETE_COMMENT_SUCCEEDED';
  static readonly DELETE_COMMENT_FAILED = '[DocumentDetails].DELETE_COMMENT_FAILED';

  static readonly ADD_REPLY_START = '[DocumentDetails].ADD_REPLY_START';
  static readonly ADD_REPLY_SUCCEEDED = '[DocumentDetails].ADD_REPLY_SUCCEEDED';
  static readonly ADD_REPLY_FAILED = '[DocumentDetails].ADD_REPLY_FAILED';

  static readonly UPDATE_REPLY_START = '[DocumentDetails].UPDATE_REPLY_START';
  static readonly UPDATE_REPLY_SUCCEEDED = '[DocumentDetails].UPDATE_REPLY_SUCCEEDED';
  static readonly UPDATE_REPLY_FAILED = '[DocumentDetails].UPDATE_REPLY_FAILED';

  static readonly DELETE_REPLY_START = '[DocumentDetails].DELETE_REPLY_START';
  static readonly DELETE_REPLY_SUCCEEDED = '[DocumentDetails].DELETE_REPLY_SUCCEEDED';
  static readonly DELETE_REPLY_FAILED = '[DocumentDetails].DELETE_REPLY_FAILED';

  static readonly LOAD_META_START = '[DocumentDetails].LOAD_META_START';
  static readonly LOAD_META_SUCCEEDED = '[DocumentDetails].LOAD_META_SUCCEEDED';
  static readonly LOAD_META_FAILED = '[DocumentDetails].LOAD_META_FAILED';

  static readonly REMOVE_ALL_SUCCEEDED = '[DocumentDetails].REMOVE_ALL_SUCCEEDED';

  static readonly UPDATE_TOKEN = '[DocumentDetails].UPDATE_TOKEN';

  static readonly LOAD_DISPLAY_BLOCK_SUCCEEDED = '[DocumentDetails].LOAD_DISPLAY_BLOCK_SUCCEEDED';

  static readonly LOAD_CURRENT_ACTIVITY_DIFF_START = '[DocumentDetails].LOAD_CURRENT_ACTIVITY_DIFF_START';
  static readonly LOAD_CURRENT_ACTIVITY_DIFF_SUCCEEDED = '[DocumentDetails].LOAD_CURRENT_ACTIVITY_DIFF_SUCCEEDED';
  static readonly LOAD_CURRENT_ACTIVITY_DIFF_FAILED = '[DocumentDetails].LOAD_CURRENT_ACTIVITY_DIFF_FAILED';
}

export const documentDetailsReducer: Reducer<DocumentDetailsState> = (state: DocumentDetailsState = CURRENT_DOCUMENT_INITIAL_STATE, action: PayloadAction<DocumentDetails>) => {
  switch (action.type) {
    case DocumentDetailsConstant.LOAD_DETAILS_START:
    case DocumentDetailsConstant.LOAD_DOC_DETAILS_START:
    case DocumentDetailsConstant.LOAD_META_START:
    case DocumentDetailsConstant.LOAD_CURRENT_ACTIVITY_DIFF_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DocumentDetailsConstant.LOAD_DETAILS_SUCCEEDED:
      const copy = {
        ...state.datas,
        details: action.payload.details,
        comments: Utils.setCommentOrder(action.payload.comments),
        attachments: action.payload.attachments,
        activities: action.payload.activities,
        token: action.payload.details.token,
        loadPdf: true,
        currentActivityDiff: new ActivityDiff(),
        metadata: action.payload.metadata
      };
      return {
        ...state,
        datas: copy,
        loading: false,
        error: null,
      };
    case DocumentDetailsConstant.LOAD_DETAILS_FAILED:
    case DocumentDetailsConstant.LOAD_DOC_DETAILS_FAILED:
    case DocumentDetailsConstant.LOAD_META_FAILED:
    case DocumentDetailsConstant.LOAD_CURRENT_ACTIVITY_DIFF_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case DocumentDetailsConstant.LOAD_DOC_DETAILS_SUCCEEDED:
      return {
        ...state,
        datas: {
          ...state.datas,
          details: action.payload.details,
          loadPdf: action.payload.loadPdf
        },
        loading: false,
        error: null,
      };
    case DocumentDetailsConstant.LOAD_META_SUCCEEDED:
      return {
        ...state,
        datas: {
          ...state.datas,
          metadata: action.payload.metadata
        },
        loading: false,
        error: null,
      };
    case DocumentDetailsConstant.LOAD_CURRENT_ACTIVITY_DIFF_SUCCEEDED:
      return {
        ...state,
        datas: {
          ...state.datas,
          currentActivityDiff: action.payload.currentActivityDiff,
          loadPdf: false
        },
        loading: false,
        error: null,
      };
    case DocumentDetailsConstant.ADD_COMMENT_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    // case DocumentDetailsConstant.ADD_COMMENT_SUCCEEDED:
    //   return {
    //     ...state,
    //     datas: {
    //       ...state.datas,
    //       comments: Utils.setCommentOrder(state.datas.comments ? state.datas.comments.concat(action.payload.comments) : action.payload.comments),
    //     },
    //     loading: false,
    //     error: null,
    //   };
    case DocumentDetailsConstant.ADD_COMMENT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case DocumentDetailsConstant.UPDATE_COMMENT_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    // case DocumentDetailsConstant.UPDATE_COMMENT_SUCCEEDED:
    //   state.datas.comments.splice(
    //     state.datas.comments.map(com => com.cdx_id).indexOf(action.payload.comments[0].cdx_id),
    //     1, action.payload.comments[0]);
    //   return {
    //     ...state,
    //     datas: {
    //       ...state.datas,
    //       comments: Utils.setCommentOrder(state.datas.comments),
    //     },
    //     loading: false,
    //     error: null,
    //   };
    case DocumentDetailsConstant.UPDATE_COMMENT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case DocumentDetailsConstant.DELETE_COMMENT_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    // case DocumentDetailsConstant.DELETE_COMMENT_SUCCEEDED:
    //   const deletedComment: CdxComment = action.payload.comments[0];
    //   const comments: CdxComment[] = [];
    //   for (const comment of state.datas.comments) {
    //     if (comment.cdx_id === deletedComment.cdx_id) {
    //       deletedComment.order = comment.order;
    //       comments.push(deletedComment);
    //     } else {
    //       comments.push(comment);
    //     }
    //   }

    //   return {
    //     ...state,
    //     datas: {
    //       ...state.datas,
    //       comments: comments
    //     },
    //     loading: false,
    //     error: null,
    //   };
    case DocumentDetailsConstant.DELETE_COMMENT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case DocumentDetailsConstant.ADD_REPLY_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    // case DocumentDetailsConstant.ADD_REPLY_SUCCEEDED:
    //   const curCom = state.datas.comments.find(com => com.cdx_id === action.payload.comments[0].cdx_id);
    //   curCom.replies = curCom.replies ? curCom.replies.concat(...action.payload.comments[0].replies) : action.payload.comments[0].replies;
    //   return {
    //     ...state,
    //     datas: {
    //       ...state.datas,
    //       comments: Utils.setCommentOrder(state.datas.comments)
    //     },
    //     loading: false,
    //     error: null,
    //   };
    case DocumentDetailsConstant.ADD_REPLY_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case DocumentDetailsConstant.UPDATE_REPLY_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    // case DocumentDetailsConstant.UPDATE_REPLY_SUCCEEDED:
    //   const upCom = state.datas.comments.find(com => com.cdx_id === action.payload.comments[0].cdx_id);
    //   upCom.replies.splice(
    //     upCom.replies.map(com => com.cdx_id).indexOf(action.payload.comments[0].replies[0].cdx_id),
    //     1, action.payload.comments[0].replies[0]);
    //   state.datas.comments.splice(
    //     state.datas.comments.map(com => com.cdx_id).indexOf(action.payload.comments[0].cdx_id),
    //     1, upCom);
    //   return {
    //     ...state,
    //     datas: {
    //       ...state.datas,
    //       comments: state.datas.comments
    //     },
    //     loading: false,
    //     error: null,
    //   };
    case DocumentDetailsConstant.UPDATE_REPLY_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case DocumentDetailsConstant.DELETE_REPLY_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    // case DocumentDetailsConstant.DELETE_REPLY_SUCCEEDED:
    //   const curComment = state.datas.comments.find(com => com.cdx_id === action.payload.comments[0].cdx_id);
    //   curComment.replies.splice(
    //     curComment.replies.map(com => com.cdx_id).indexOf(action.payload.comments[0].replies[0].cdx_id),
    //     1, action.payload.comments[0].replies[0]);
    //   state.datas.comments.splice(
    //     state.datas.comments.map(com => com.cdx_id).indexOf(action.payload.comments[0].cdx_id),
    //     1, curComment);
    //   return {
    //     ...state,
    //     datas: {
    //       ...state.datas,
    //       comments: state.datas.comments
    //     },
    //     loading: false,
    //     error: null,
    //   };
    case DocumentDetailsConstant.DELETE_REPLY_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case DocumentDetailsConstant.LOAD_ATTACHMENT_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DocumentDetailsConstant.LOAD_ATTACHMENT_SUCCEEDED:
      let attachmentIdToSearchFor;
      attachmentIdToSearchFor = action.payload.attachments.length > 1 ? action.payload.attachments[1].cdx_id : action.payload.attachments[0].cdx_id;
      const upsertAttachement: CdxAttachment = action.payload.attachments[0];
      let found = false;
      let attachments: CdxAttachment[] = state.datas.attachments.map(att => {
        if (att.cdx_id === attachmentIdToSearchFor) {
          found = true;
          return upsertAttachement;
        }
        return att;
      });
      if (!found) {
        attachments = attachments.concat(upsertAttachement);
      }
      return {
        ...state,
        datas: {
          ...state.datas,
          attachments: attachments,
        },
        loading: false,
        error: null,
      };
    case DocumentDetailsConstant.LOAD_ATTACHMENT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case DocumentDetailsConstant.LOAD_HISTORY_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DocumentDetailsConstant.LOAD_HISTORY_SUCCEEDED:
      return {
        ...state,
        datas: {
          ...state.datas,
          activities: action.payload.activities,
        },
        loading: false,
        error: null,
      };
    case DocumentDetailsConstant.LOAD_HISTORY_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };


    case DocumentDetailsConstant.LOAD_COMMENT_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DocumentDetailsConstant.LOAD_COMMENT_SUCCEEDED:
      const upsertComment: CdxComment = action.payload.comments[0];
      let foundCom = false;
      let commentss: CdxComment[] = state.datas.comments.map(comm => {
        if (comm.cdx_id === upsertComment.cdx_id) {
          foundCom = true;
          return upsertComment;
        }
        return comm;
      });
      if (!foundCom) {
        commentss = commentss.concat(upsertComment);
      }
      return {
        ...state,
        datas: {
          ...state.datas,
          comments: Utils.setCommentOrder(commentss),
        },
        loading: false,
        error: null,
      };
    case DocumentDetailsConstant.LOAD_COMMENT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case DocumentDetailsConstant.UPLOAD_ATTACHMENT_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DocumentDetailsConstant.UPLOAD_ATTACHMENT_SUCCEEDED:
      return {
        ...state,
        datas: {
          ...state.datas,
          attachments: state.datas.attachments ? state.datas.attachments.concat(action.payload.attachments) : action.payload.attachments,
        },
        loading: false,
        error: null,
      };
    case DocumentDetailsConstant.UPLOAD_ATTACHMENT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case DocumentDetailsConstant.DELETE_ATTACHMENT_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DocumentDetailsConstant.DELETE_ATTACHMENT_SUCCEEDED:
      return {
        ...state,
        datas: {
          ...state.datas,
          attachments: state.datas.attachments.filter(att => att.cdx_id !== action.payload.attachments[0].cdx_id)
        },
        loading: false,
        error: null,
      };
    case DocumentDetailsConstant.DELETE_ATTACHMENT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case DocumentDetailsConstant.UPDATE_ATTACHMENT_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DocumentDetailsConstant.UPDATE_ATTACHMENT_SUCCEEDED:
      const toReplace = state.datas.attachments.map(att => att.cdx_id).indexOf(action.payload.attachments[0].cdx_id);
      const updated = action.payload.attachments[0];
      updated.cdx_thumbnail = state.datas.attachments[toReplace].cdx_thumbnail;
      state.datas.attachments.splice(toReplace, 1, updated);
      return {
        ...state,
        datas: {
          ...state.datas,
          attachments: state.datas.attachments
        },
        loading: false,
        error: null,
      };
    case DocumentDetailsConstant.UPDATE_ATTACHMENT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case DocumentDetailsConstant.REMOVE_ALL_SUCCEEDED:
      return {
        ...state,
        datas: null,
        displayInfoBlock: false,
        loading: false,
        error: null,
      };
    case DocumentDetailsConstant.UPDATE_TOKEN:
      state.datas.details.token = action.payload.token;
      return {
        ...state,
        datas: state.datas
      };
    case DocumentDetailsConstant.LOAD_DISPLAY_BLOCK_SUCCEEDED:
      return {
        ...state,
        displayInfoBlock: action.payload.displayInfoBlock
      };
  }
  return state;
};
