import { FromJson } from './fromJson';
import { Privacy } from './privacy';

export enum FieldType {
  AUTHOR = 'AUTHOR',
  DATE = 'DATE', STRING = 'STRING', DECIMAL = 'DECIMAL', INTEGER = 'INTEGER', BOOLEAN = 'BOOLEAN', LIST = 'LIST', THESAURUS = 'THESAURUS', XML = 'XML' }

export class Field extends FromJson {

  code: string;
  type: FieldType;
  format: any;
  label: {};
  privacy: Privacy;

  static fromJson<T>(json: any, ctor: (new () => T)): T {
    const f = super.fromJson(json, ctor);
    f['type'] = FieldType[json.type as string];
    f['label'] = { // FIXME get directly string, back knows the locale
      fr : json.label,
      en : json.label
    };
    return f;
  }
}

