import { Injectable } from '@angular/core';
import { convertToParamMap, ParamMap, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/Observable';
import { of, zip } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { SearchPath } from '../../../../core/models/search-path';
import { SearchItem, SearchOperator } from '../../../../core/models/search-item';
import { FieldType } from '../../../../core/models/field';
import { LocaleService } from '../../../../core/services/locale/locale.service';
import { AbstractCfgFieldService } from '../../../../core/redux/field/service/abstract-cfg-field.service';

@Injectable({
  providedIn: 'root'
})
export abstract class AbstractSearchService {

  constructor(
    protected translateService: TranslateService,
    protected cfgFieldService: AbstractCfgFieldService,
    protected localeService: LocaleService
  ) {
  }

  public buildSearchItemsFromPath(searchPath: SearchPath): Observable<SearchItem> {
    let label;
    let fieldType: FieldType;
    let observable: Observable<any>;
    if (searchPath.isMeta) {
      label = this.translateService.instant('META_FIELDS.' + searchPath.code);
      fieldType = SearchPath.getMetaDatasFieldType(searchPath.code);
      observable = of([label, fieldType]);
    } else {
      observable = this.cfgFieldService.loadFields(searchPath.code)
        .pipe(map(set => {
          if (!!set[searchPath.code]) {
            label = set[searchPath.code].label[this.localeService.getLocale()];
            fieldType = set[searchPath.code].type;
            return [label, fieldType];
          }
          return [searchPath.code, FieldType.STRING];
        }));
    }

    observable = observable.pipe(
      map(tab => {
        return new SearchItem(-1,
          tab[0],
          searchPath.path,
          searchPath.value,
          true,
          tab[1],
          searchPath.operator);
      })
    );

    return observable;
  }

  public buildSearchitemsFromQueryParams(queryParams: Params): Observable<SearchItem[]> {
    let qSearchItem: SearchItem[] = [];
    const paramMap: ParamMap = convertToParamMap(queryParams);

    const searchPathes: SearchPath[] = paramMap.keys
      .filter(key => {
        if (key === '_q') {
          qSearchItem = paramMap
            .getAll('_q')
            .map(qValue => qValue.split(','))
            .reduce((acc, val) => acc.concat(val), [])
            .map(qValue => new SearchItem(-1, null, null, qValue.trim(), true, null, SearchOperator.e));
          return false;
        } else {
          return !key.startsWith('_');
        }
      })
      .map(key => paramMap.getAll(key).map(value => new SearchPath(key, value)))
      .reduce((acc, val) => acc.concat(val), []); // flatMap


    const fieldCodes: string[] = searchPathes
      .filter(searchPath => !searchPath.isMeta)
      .map(searchPath => searchPath.code);
    const res: Observable<SearchItem[]> = this.cfgFieldService.loadFields(...fieldCodes)
      .pipe(
        switchMap(() => {
          if (!searchPathes.length) {
            return of([]);
          }
          const searchItem$ = searchPathes.map(searchPath => this.buildSearchItemsFromPath(searchPath).pipe(take(1)));
          return zip(...searchItem$);
        }),
        map((items: SearchItem[]) => {
          const arr = items.concat(qSearchItem);
          return items
            .concat(qSearchItem)
            .map((item, index, array) => {
              item.id = index;
              return item;
            });
        })
      );
    return res;

  }
}
