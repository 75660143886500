export enum LockType {
  OFFICE_365 = 'o365'
}

export class LockInformation {
  lock_type?: LockType;
  office_id?: string;
  link?: string;
}

export class Lock {
  locked?: boolean;
  cdx_author?: number;
  cdx_creation_date?: Date;
  lock_information?: LockInformation;
  cdx_id?: string;
}
