import { CdxClassification } from './cdx-classification';
import { CdxData } from './cdx-data';
import { EsFile } from './EsFile';
import { EsAuthor } from './EsAuthor';
import { Entity } from './Entity';
import { I18nCode } from './I18n-code';

export class CdxDocument {
  cdx_id: string;
  cdx_version: number;
  cdx_type: I18nCode;
  cdx_author: EsAuthor;
  cdx_creation_date: Date;
  cdx_modification_author: EsAuthor;
  cdx_modification_date: Date;
  cdx_date_eol: Date;
  cdx_file: EsFile;
  cdx_file_formats: {[key: string]: EsFile};
  cdx_title: string;
  cdx_subtitle: string;
  cdx_classification: CdxClassification;
  cdx_owner: number;
  cdx_datas: {[key: string]: CdxData} = {}; // Map<string, CdxData> = new Map<string, CdxData>();
  cdx_links: {[key: string]: Entity[]};
  cdx_status: string;
  token: string;
  external_id: string;
}
