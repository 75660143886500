import { Inject, Injectable } from '@angular/core';
import { forkJoin, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-common';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { AzureService } from '../azure.service';
import { AuthenticationService } from '../../../../modules/authentication/authentication.service';
import { ConfigurationActions } from '../../../../modules/configuration/configuration.actions';
import { HttpErrorInterceptor } from '../../../../modules/http-error-interceptor/http-error-interceptor';
import { BlobFile } from '../../../models/blob-file';
import { ShareLinkData } from '../../../models/share-link-data';

export class UploadDoneItem {
  uploadDone: boolean;
  driveItem: HttpResponse<any> | null;
  constructor(uploadDone: boolean, driveItem: HttpResponse<any> | null) {
    this.uploadDone = uploadDone;
    this.driveItem = driveItem;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ShareFileService extends AzureService {

  private static readonly DRIVE_URL = 'https://graph.microsoft.com/v1.0/me/drive/';
  private static readonly ROOT = 'root:/';
  private static readonly CONTENT = '/content';
  private static readonly CREATE_UPLOAD_SESSION = '/createUploadSession';
  private static readonly ROOT_CHILDREN = 'root/children';
  private static readonly ITEMS = 'items/';
  private static readonly CREATE_LINK = '/createLink';
  private static readonly OPERATOR = ':';
  private static readonly CHUNK_LENGTH = 320 * 1024;
  private static readonly BIG_FILE_SIZE = 400 * 1024;
  private static readonly MAX_NB_OF_CALLS_FOR_CHUNKS = 1000;

  public static readonly FORCE_DELETE_DRIVE_ITEM = 'bypass-shared-lock';

  private shareDirName = 'Nexia';

  private static createNexiaFolder(name: string): any {
    const folder: any = {};
    folder['name'] = name;
    folder['folder'] = {};
    folder['@microsoft.graph.conflictBehavior'] = 'fail';
    return folder;
  }

  private static createNexiaItem(): any {
    const item: any = {};
    item['@microsoft.graph.conflictBehavior'] = 'rename';
    return item;
  }

  constructor(
    @Inject(MSAL_GUARD_CONFIG) msalGuardConfig: MsalGuardConfiguration,
    broadcastService: MsalBroadcastService,
    msAuthService: MsalService,
    httpClient: HttpClient,
    translateService: TranslateService,
    authenticationService: AuthenticationService,
    configAction: ConfigurationActions
  ) {
    super(msalGuardConfig, broadcastService, msAuthService, httpClient, translateService, authenticationService, configAction);
    const configShareDirName = this.configAction.get('application.AZURE_MSAL_SHARE_DIR_NAME');
    this.shareDirName = !!configShareDirName && configShareDirName.length ? configShareDirName : this.shareDirName;
  }

  public checkOfficeLogin(): Observable<AuthenticationResult> {
    return this.checkLogin();
  }

  public checkLoginAndUpsertShareFolder(): Observable<boolean> {
    const isDone: Subject<boolean> = new Subject<boolean>();
    this.checkLogin().pipe(
      switchMap((response: AuthenticationResult) => {
        if (response) {
          return this.createDriveFolderIfDoesNotExist();
        } else {
          // TODO treat login out
           throw new Error('Failed to login');
        }
      })
    ).subscribe((response: any) => {
      console.log(response);
      isDone.next(true);
      isDone.complete();
    }, error => {
      console.error('error: ', error);
      if (error.status === 404) {
        // TODO folder allready created
      }
      if (error.message === 'user_cancelled: User cancelled the flow.') {
        // TODO user canceled the azure connection
      }
      isDone.next(false);
      isDone.complete();
    });
    return isDone.asObservable();
  }

  public checkloginAndCheckinFile(driveFileId: string): Observable<File | null> {
   return this.checkLogin().pipe(
      switchMap((response: AuthenticationResult) => {
        if (response) {
          return this.checkinFile(driveFileId);
        } else {
          // TODO treat login out
          throw new Error('Failed to login');
        }
      }),
     catchError((error) => {
       if (error.message === 'user_cancelled: User cancelled the flow.') {
         // TODO user canceled the azure connection
       }
       return throwError(error);
     })
    );
  }

  public addFileToDriveAndCreateShareLink(file: BlobFile): Observable<any> {
    let fileId = '';
    const headers = new HttpHeaders().set(HttpErrorInterceptor.BYPASS_HEADER, '');
    let request: Observable<HttpResponse<any>>;
    if (file.fileSize >= ShareFileService.BIG_FILE_SIZE) {
      request = this.uploadBigFile(file, headers);
    } else {
      request = this.uploadSmallFile(file, headers);
    }
    return request.pipe(
      switchMap((response: HttpResponse<any>) => {
        fileId = response.body.id;
        return this.createShareLink(fileId);
      }),
      catchError((error) => {
        // TODO treat error for upload file on drive
        console.error(error);
        return throwError(error);
      }),
      map((shareLinkData: any) => {
        return {
          fileId,
          shareLink: shareLinkData.link.webUrl
        } as ShareLinkData;
      })
    );
  }

  private checkinFile(driveFileId: string): Observable<File | null> {
    return forkJoin([this.getDriveFile(driveFileId), this.getDriveItem(driveFileId)])
      .pipe(
        switchMap(([blob, driveItem]: [HttpResponse<Blob>, HttpResponse<any>]) => {
          console.log(blob);
          console.log(driveItem);
          return of(new File([blob.body], driveItem.body.name , {type: blob.body.type}));
        }),
        catchError((error: HttpErrorResponse) => {
          return of(null);
        })
      );
  }

  private getDriveFile(driveFileId: string): Observable<HttpResponse<Blob>> {
    return this.httpClient.get(ShareFileService.DRIVE_URL + ShareFileService.ITEMS + driveFileId + ShareFileService.CONTENT, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  private getDriveItem(driveFileId: string): Observable<HttpResponse<any>> {
    return this.httpClient.get(ShareFileService.DRIVE_URL + ShareFileService.ITEMS + driveFileId, {
      observe: 'response'
    });
  }

  public deleteDriveItem(driveFileId: string): Observable<HttpResponse<any>> {
    const headers = new HttpHeaders().set('prefer', ShareFileService.FORCE_DELETE_DRIVE_ITEM);
    return this.httpClient.delete(ShareFileService.DRIVE_URL + ShareFileService.ITEMS + driveFileId, {
      headers,
      observe: 'response'
    });
  }

  private createDriveFolderIfDoesNotExist(): Observable<any> {
    const folder = ShareFileService.createNexiaFolder(this.shareDirName);
    return this.getDriveFolder().pipe(
      catchError((error: HttpErrorResponse) => {
        const parsedError: any = JSON.parse(error.error);
        if (error.status === 404 && parsedError.error.code === 'itemNotFound') {
          return of(null);
        }
      }),
      switchMap((driveFolder) => {
        if (driveFolder) {
          return of(JSON.parse(driveFolder.body));
        } else {
          return this.httpClient.post(ShareFileService.DRIVE_URL + ShareFileService.ROOT_CHILDREN, folder, {
            responseType: 'text',
            observe: 'response'
          }).pipe(
            map((response: HttpResponse<any>) => JSON.parse(response.body))
          );
        }
      })
    );
  }

  private createShareLink(fileId: string): Observable<any> {
    const shareLinkData = {
      type: 'edit',
      scope: 'organization'
    };
    return this.httpClient.post(ShareFileService.DRIVE_URL + ShareFileService.ITEMS + fileId + ShareFileService.CREATE_LINK, shareLinkData);
  }

  private uploadSmallFile(file: BlobFile, headers: HttpHeaders): Observable<HttpResponse<any>> {
    return this.httpClient.put(ShareFileService.DRIVE_URL + ShareFileService.ROOT + this.shareDirName + '/' + file.filename + ShareFileService.OPERATOR + ShareFileService.CONTENT,
      file.blob, { headers: headers, observe: 'response' }
    );
  }

  private uploadBigFile(file: BlobFile, headers: HttpHeaders): Observable<HttpResponse<any>> {
    return this.getUploadSession(file, headers).pipe(
      switchMap((uploadSession: HttpResponse<any>) => {
        const uploadUrl: string = uploadSession.body.uploadUrl;
        return this.uploadChunks(file, uploadUrl);
      }),
      filter((uploadDoneItem: UploadDoneItem) => uploadDoneItem.uploadDone && !!uploadDoneItem.driveItem),
      map((uploadDoneItem: UploadDoneItem) => uploadDoneItem.driveItem)
    );
  }

  private getUploadSession(file: BlobFile, headers: HttpHeaders): Observable<HttpResponse<any>> {
    const bodyObject: any = {
      item: ShareFileService.createNexiaItem()
    };
    return this.httpClient.post(ShareFileService.DRIVE_URL + ShareFileService.ROOT + this.shareDirName + '/' + file.filename + ShareFileService.OPERATOR + ShareFileService.CREATE_UPLOAD_SESSION, bodyObject, {
      headers: headers,
      observe: 'response'
    });
  }

  private uploadChunks(file: BlobFile, uploadUrl: string): Observable<UploadDoneItem> {
    const uploadDone: Subject<UploadDoneItem> = new Subject<UploadDoneItem>();
    return this._uploadChunks(file, uploadUrl, 0, ShareFileService.CHUNK_LENGTH, uploadDone);
  }

  private _uploadChunks(file: BlobFile, uploadUrl: string, position = 0, chunkLength, uploadDone: Subject<UploadDoneItem>, nbOfCalls = 1): Observable<UploadDoneItem> {
    let chunk: ArrayBuffer;
    if (nbOfCalls >= ShareFileService.MAX_NB_OF_CALLS_FOR_CHUNKS) {
      console.error('Exceeded max number of calls for uploading chunk');
      throw new Error('Exceeded max number of calls for uploading chunk');
    }
    try {
      const stopByte = position + chunkLength;
      this.readFragmentAsync(file, position, stopByte).then((result: ArrayBuffer) => {
        chunk = result;
        // if (!chunk || chunk.byteLength <= 0) {
        //   uploadDone.next(new UploadDoneItem(true, null));
        //   return;
        // }

        try {
          console.log('Request sent for uploadFragmentAsync');
          this.uploadChunk(chunk, uploadUrl, position, file.fileSize).subscribe((response: HttpResponse<any>) => {
            // Check the response.
            if (response.status !== 202 && response.status !== 201 && response.status !== 200) {
              throw new Error('Put operation did not return expected response');
            }
            if (response.status === 201 || response.status === 200) {
              console.log('Reached last chunk of file.  Status code is: ' + response.status);
              uploadDone.next(new UploadDoneItem(true, response));
            } else {
              console.log('Continuing - Status Code is: ' + response.status);
              position = Number(response.body.nextExpectedRanges[0].split('-')[0]);
              this._uploadChunks(file, uploadUrl, position, chunkLength, uploadDone, nbOfCalls++);
            }

            console.log('Response received from uploadChunk.');
            console.log('Position is now ' + position);
          }, (error: HttpErrorResponse) => {
            console.error('ERROR on uploading chunk');
            throw new Error(error.message);
          });
        } catch (e) {
          console.log('Error occured when calling uploadChunk::' + e);
          throw new Error(e);
        }
      }, (error) => {
        console.error('ERROR on reading chunk');
      });
    } catch (error) {
      console.error('ERROR on reading chunk');
    }
    return uploadDone.asObservable();
  }

  private uploadChunk(chunk: ArrayBuffer, uploadUrl: string, position: number, totalLength: number): Observable<any> {
    const max: number = position + chunk.byteLength - 1;
    const crHeader = `bytes ${position}-${max}/${totalLength}`;
    const chunkHeaders: HttpHeaders = new HttpHeaders().set('Content-Range', crHeader);
    return this.httpClient.put(uploadUrl, chunk, {headers: chunkHeaders, observe: 'response'});
  }

  private readFragmentAsync(file: BlobFile, startByte: number, stopByte: number): Promise<ArrayBuffer> {
    let fragment: ArrayBuffer;
    const reader = new FileReader();
    const blob: Blob = file.blob.slice(startByte, stopByte);
    reader.readAsArrayBuffer(blob);
    return new Promise<ArrayBuffer>((resolve, reject) => {
      reader.onloadend = (event: ProgressEvent<FileReader>) => {
        if (reader.readyState === reader.DONE) {
          fragment = reader.result as ArrayBuffer;
          resolve(fragment);
        }
      };
    });
  }

  private getDriveFolder(): Observable<HttpResponse<any>> {
    const headers = new HttpHeaders().set(HttpErrorInterceptor.BYPASS_HEADER, '');
    return this.httpClient.get(ShareFileService.DRIVE_URL + ShareFileService.ROOT + this.shareDirName, {
      headers: headers,
      responseType: 'text',
      observe: 'response'
    });
  }
}
