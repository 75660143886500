import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastaModule, ToastaService } from 'ngx-toasta';
import { ToasterComponent } from './toaster.component';

@NgModule({
  imports: [
    CommonModule,
    ToastaModule.forRoot(),
  ],
  declarations: [ToasterComponent],
  providers: [ToastaService],
  exports: [ToasterComponent]
})
export class ToasterModule { }
