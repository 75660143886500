import { Component, OnInit } from '@angular/core';
import { ToastaConfig } from 'ngx-toasta';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss']
})

export class ToasterComponent implements OnInit {

  constructor(
    private toastaConfig: ToastaConfig
  ) {
    this.toastaConfig.timeout = 4000;
  }

  ngOnInit() { }

}
