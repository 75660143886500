import { EsPage } from './es-page';
import { CdxDocument } from './cdx-document';
import { CdxComment } from './cdx-comment';
import { ActivityDiff, CdxActivity } from './cdx-activity';
import { CdxAttachment } from './cdx-attachment';
import { MetadataCodes } from './metadata';

export class DocumentDetails {
  details: CdxDocument;
  comments: CdxComment[];
  attachments: CdxAttachment[];
  activities: EsPage<CdxActivity>;
  token: string;
  displayInfoBlock?: boolean;
  loadPdf?: boolean;
  currentActivityDiff: ActivityDiff;
  metadata: {-readonly [key in MetadataCodes]?: any};
}
