import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastaService } from 'ngx-toasta';
import { AlertModalService } from '../alert-message/service/alert-modal.service';


@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  public static readonly BYPASS_HEADER = 'X-Skip-Error-Interceptor';

  constructor(
    private translateService: TranslateService,
    private toastaService: ToastaService,
    private alertService: AlertModalService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has(HttpErrorInterceptor.BYPASS_HEADER)) {
      return next.handle(req);
    }
    return next.handle(req).pipe(
      catchError(
        (error: HttpErrorResponse, caught: Observable<HttpEvent<any>>) => {

          if ( error.status === 401 ) {
            this.alertService.raiseDisconnectError();
          }
          let errorMsg = this.translateService.instant('HTTP_ERRORS.' + error.status, {status: error.status});

          if (!!error.error) {
            try {
              const parse = JSON.parse(error.error);
              if (!!parse.detail) {
                errorMsg = parse.detail;
              }
            } catch (e) {
              if (!!error.error.detail) {
                errorMsg = error.error.detail;
              }
            }
          }

          this.toastaService.error(errorMsg);
          return throwError(error as HttpErrorResponse);
      })
    );
  }

}
