import { SearchItem, SearchOperator } from './search-item';
import { FieldType } from './field';
import { CurrentContextService } from '../redux/current-context/service/current-context.service';

export class SearchPath {

  /*GED*/
  public static readonly META_CDX_ID = 'cdx_id';
  public static readonly META_CDX_TYPE = 'cdx_type';
  public static readonly META_CDX_STATUS = 'cdx_status';
  public static readonly META_CDX_TITLE = 'cdx_title';
  public static readonly META_CDX_SUBTITLE = 'cdx_subtitle';
  public static readonly META_CDX_VERSION = 'cdx_version';
  public static readonly META_CDX_CREATION_DATE = 'cdx_creation_date';
  public static readonly META_CDX_AUTHOR = 'cdx_author';
  public static readonly META_CDX_MODIFICATION_DATE = 'cdx_modification_date';
  public static readonly META_CDX_MODIFICATION_AUTHOR = 'cdx_modification_author';
  public static readonly META_CDX_FILE = 'cdx_file';
  public static readonly META_CDX_EXTERNAL_ID = 'external_id';
  // public static readonly META_CDX_CLASSIFICATION = 'cdx_classification';
  public static readonly META_CDX_TEAM = 'teams';
  /*WORKFLOW*/
  public static readonly META_PROCESS_INSTANCE_ID = 'process_instance_id';
  public static readonly META_DEPLOYMENT_ID = 'deployment_id';
  public static readonly META_PROCESS_DEF = 'process_def';
  public static readonly META_TASK_ID = 'task_id';
  public static readonly META_TASK_DEF = 'task_def';

  public static readonly META_DATAS = [
    /*GED*/
    SearchPath.META_CDX_ID,
    SearchPath.META_CDX_TYPE,
    SearchPath.META_CDX_STATUS,
    SearchPath.META_CDX_TITLE,
    SearchPath.META_CDX_SUBTITLE,
    SearchPath.META_CDX_VERSION,
    SearchPath.META_CDX_CREATION_DATE,
    SearchPath.META_CDX_AUTHOR,
    SearchPath.META_CDX_MODIFICATION_DATE,
    SearchPath.META_CDX_MODIFICATION_AUTHOR,
    SearchPath.META_CDX_FILE,
    SearchPath.META_CDX_EXTERNAL_ID,
    // SearchPath.META_CDX_CLASSIFICATION,
    SearchPath.META_CDX_TEAM,
    /*WORKFLOW*/
    SearchPath.META_PROCESS_INSTANCE_ID,
    SearchPath.META_DEPLOYMENT_ID,
    SearchPath.META_PROCESS_DEF,
    SearchPath.META_TASK_DEF,
    SearchPath.META_TASK_ID
  ];

  public static readonly PATH_PART_SEPARATOR = '.';
  public static readonly PREFIX_CDX_DATAS = 'cdx_datas';
  public static readonly PREFIX_CDX_LINKS = 'cdx_links';
  public static readonly PREFIX_CDX_WKF = 'cdx_wkf';

  public static readonly SUFFIX_SEARCH_ON_PATH = 'path';
  public static readonly SUFFIX_SEARCH_ON_ITEM = 'item';
  public static readonly SUFFIX_VALUE = 'value';
  public static readonly SUFFIX_CODE = 'code';
  public static readonly SUFFIX_I18N = 'i18n'; // ? et i18n.fr ???
  public static readonly SUFFIX_FR = 'fr';
  public static readonly SUFFIX_EN = 'en';

  public static readonly SUFFIXES = [
    SearchPath.SUFFIX_VALUE,
    SearchPath.SUFFIX_CODE,
    SearchPath.SUFFIX_I18N,
    SearchPath.SUFFIX_EN,
    SearchPath.SUFFIX_FR,
    SearchPath.SUFFIX_SEARCH_ON_ITEM,
    SearchPath.SUFFIX_SEARCH_ON_PATH
  ];

  public path: string = null;
  public operator: SearchOperator = SearchOperator.e;
  public suffix: string = null;
  public isMeta = false;
  public isLink = false;
  public code: string = null;
  public linkCode: string = null;
  public value: string;
  public isWkf = false;

  private static splitPath(path: string): string[] {
    return path.split(SearchPath.PATH_PART_SEPARATOR);
  }

  private static isWkf(path: string): boolean {
    return path.indexOf(SearchPath.PREFIX_CDX_WKF) > -1;
  }

  static isEntityPath(path: string): boolean {
    let isEntity = false;
    if (!!path) {
      const pathPrefix: string = SearchPath.splitPath(path)[0];
      isEntity = pathPrefix === SearchPath.PREFIX_CDX_LINKS;
    }
    return isEntity;
  }

  static getEntityTypeColorByItemPath(path: string): string {
    if (!!path) {
      return CurrentContextService.getEntityTypeColor(this.getEntityTypeByItemPath(path));
    }
    return '';
  }

  static getEntityTypeByItemPath(path: string): string {
    return SearchPath.splitPath(path)[1];
  }

  public static getMetaDatasFieldType(metaDataCode: string) {
    switch (metaDataCode) {
      /*GED*/
      case SearchPath.META_CDX_ID:
      case SearchPath.META_CDX_TYPE:
      case SearchPath.META_CDX_TITLE:
      case SearchPath.META_CDX_SUBTITLE:
      case SearchPath.META_CDX_STATUS:
      case SearchPath.META_CDX_TEAM:
      case SearchPath.META_CDX_EXTERNAL_ID:
      case SearchPath.META_PROCESS_DEF:
      case SearchPath.META_PROCESS_INSTANCE_ID:
      case SearchPath.META_DEPLOYMENT_ID:
      case SearchPath.META_TASK_DEF:
      case SearchPath.META_TASK_ID:
        return FieldType.STRING;
      case SearchPath.META_CDX_VERSION:
        return FieldType.INTEGER;
      case SearchPath.META_CDX_CREATION_DATE:
      case SearchPath.META_CDX_MODIFICATION_DATE:
        return FieldType.DATE;
      case SearchPath.META_CDX_AUTHOR:
      case SearchPath.META_CDX_MODIFICATION_AUTHOR:
        return FieldType.AUTHOR; // pas sûr qu'il voit réellement le jour celui-ci...
      default:
        return null;
    }
  }

  public getPathWithoutSuffix(): string {
    let result = this.code;
    if (!this.isMeta) {
      result = SearchPath.PREFIX_CDX_DATAS + SearchPath.PATH_PART_SEPARATOR + result;
    }
    if (this.isLink) {
      result = SearchPath.PREFIX_CDX_LINKS + SearchPath.PATH_PART_SEPARATOR + this.linkCode + SearchPath.PATH_PART_SEPARATOR + result;
    }
    return result;
  }

  constructor(path: string, value = null) {
    if (value != null && typeof value !== 'undefined') {
      this.value = '' + value;
    }
    this.path = path;
    // SearchOperator
    let split = path.split(SearchItem.OPERATOR_SEPARATOR);
    const tmpPath = split[0];
    if (split.length > 1) {
      this.operator = SearchOperator[path[1]];
    }

    this.isWkf = SearchPath.isWkf(tmpPath);

    // SUFFIXES
    split = tmpPath.split(SearchPath.PATH_PART_SEPARATOR);
    let last = split[split.length - 1];
    let suffix = null;
    while (SearchPath.SUFFIXES.indexOf(last) > -1) {
      suffix = suffix ? last + SearchPath.PATH_PART_SEPARATOR + suffix : last;
      split.pop();
      last = split[split.length - 1];
    }
    this.suffix = suffix;
    // if (SearchPath.SUFFIXES.indexOf(last) > -1) {
    //   this.suffix = last;
    //   split.pop();
    // }

    // Code
    last = split[split.length - 1];
    if (SearchPath.META_DATAS.indexOf(last) > -1) {
      this.isMeta = true;
    }
    this.code = last;
    split.pop();

    // ??
    if (!!split.length) {
      if (split[0] === SearchPath.PREFIX_CDX_LINKS) {
        this.isLink = true;
        this.linkCode = split[1];
      }
    }

  }
}
