import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PopupRequest } from '@azure/msal-browser';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-common';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigurationActions } from '../../../modules/configuration/configuration.actions';
import { AuthenticationService } from '../../../modules/authentication/authentication.service';


@Injectable({
  providedIn: 'root'
})
export abstract class AzureService {

  protected static query(): Observable<HttpParams> {
    const httpParams = new HttpParams();
    return of(httpParams);
  }

  constructor(
    protected msalGuardConfig: MsalGuardConfiguration,
    protected broadcastService: MsalBroadcastService,
    protected msAuthService: MsalService,
    protected httpClient: HttpClient,
    protected translateService: TranslateService,
    protected authenticationService: AuthenticationService,
    protected configAction: ConfigurationActions
  ) {
  }

  protected checkLogin(): Observable<AuthenticationResult> {
    const msalGuardConfig: PopupRequest | undefined = this.msalGuardConfig.authRequest ? {...this.msalGuardConfig.authRequest} as PopupRequest : undefined;
    return this.msAuthService.loginPopup(msalGuardConfig);
  }

  public getIsMsAlConfig(): boolean {
    const applicationConfig: any = this.configAction.get('application');
    return !!applicationConfig['AZURE_MSAL_AUTH_CLIENTID'] && !!applicationConfig['AZURE_MSAL_AUTH_AUTHORITY']
      && !!applicationConfig['AZURE_MSAL_AUTH_REDIRECT_URI'] && !!applicationConfig['AZURE_MSAL_GRAPH_API_URL']
      && !!applicationConfig['AZURE_MSAL_OUTLOOK_URL'];
  }
}
