import { Lock } from './lock';

export enum MetadataCodes {
  ARCHIVE_LOCK = 'archiveLock',
  OBJECT_LOCK = 'objectLock'
}

export class Metadata {
  lock?: Lock;
}
